<template>
  <div>
    <b-row cols="2">
      <b-col cols="12" lg="3" md="4" sm="12" xs="12">
        <b-card class="card-height-chatgpt">
          <b-nav vertical>
            <b-button class="style" @click="newConversation" variant="primary">
              <feather-icon icon="PlusIcon" size="18" class="feather" />Add New
              Chat</b-button
            >
            <loading
              :active.sync="listLoading"
              :can-cancel="true"
              :is-full-page="false"
              color="#4285f4"
              loader="dots"
            />
            <div
              v-for="(i, idx) in listConversationData"
              :key="idx"
              v-show="!listLoading"
            >
              <div
                :class="
                  i.conversation_id === selectedConversationId &&
                  selectedConversationId !== ''
                    ? 'selected-conversation'
                    : 'no-selected-conversation'
                "
              >
                <a
                  class="style-list"
                  @click="showConversation(i.conversation_id)"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    size="18"
                    class="feather"
                  />
                  <span>{{ i.topic }}</span>

                  <div class="action-button">
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="feather"
                      @click="deleteConversation(i.conversation_id)"
                      v-if="i.conversation_id === selectedConversationId"
                    />
                  </div>
                </a>
              </div>
            </div>
          </b-nav>
        </b-card>
      </b-col>

      <b-col cols="12" lg="9" md="8" sm="12" xs="12">
        <b-card class="card-height-chatgpt">
          <div class="chatbox">
            <loading
              :active.sync="conversationLoading"
              :can-cancel="true"
              :is-full-page="false"
              color="#4285f4"
              loader="dots"
            />
            <div class="text-outer" v-if="responseData.length != 0">
              <div
                :class="item.role == 'assistant' ? 'text-to' : 'text-from'"
                class="text-inner"
                v-for="(item, index) in responseData"
                :key="index"
              >
                <b-row class="d-flex justify-content-center m-0">
                  <b-col cols="12" lg="8">
                    <b-row>
                      <b-col class="col-auto avatar-chat">
                        <b-avatar
                          v-if="item.role !== 'assistant'"
                          size="32"
                          variant="primary"
                        />
                        <b-avatar
                          v-if="item.role == 'assistant'"
                          size="32"
                          src="../../../../public/icon.png"
                          variant="primary"
                        />
                      </b-col>
                      <b-col class="message-style">
                        <div
                          ref="contentElm"
                          v-html="getContentData(item.content)"
                        ></div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div v-if="isLoading == true" class="text-outer">
              <div class="div-color text-inner">
                <b-row class="d-flex justify-content-center m-0">
                  <b-col cols="12" lg="8">
                    <b-row>
                      <b-col class="col-auto">
                        <b-avatar
                          size="32"
                          src="../../../../public/icon.png"
                          variant="primary"
                        />
                      </b-col>
                      <b-col> typing... </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div v-if="responseData.length == 0 && addNewConversation">
              <b-row class="m-0">
                <b-col
                  class="d-flex align-item-center justify-content-center mt-3 mb-2"
                >
                  <h1>Meet Walter, your AI Assistant</h1>
                </b-col>
              </b-row>

              <b-row class="m-0">
                <b-col class="d-flex align-item-center justify-content-center">
                  <h3>Try These Examples</h3>
                </b-col>
              </b-row>

              <b-row class="m-0">
                <b-col
                  class="d-flex align-item-center justify-content-center mb-1"
                >
                  <span
                    class="example-listing"
                    @click="defaultMessage('How to ssh gcp instance?')"
                  >
                    "How to ssh gcp instance?"
                  </span>
                </b-col>
              </b-row>

              <b-row class="m-0">
                <b-col
                  class="d-flex align-item-center justify-content-center mb-1"
                >
                  <span
                    class="example-listing"
                    @click="defaultMessage('Explain Firebase Authentication')"
                  >
                    "Explain Firebase Authentication"
                  </span>
                </b-col>
              </b-row>

              <b-row class="m-0">
                <b-col
                  class="d-flex align-item-center justify-content-center mb-1"
                >
                  <span
                    class="example-listing"
                    @click="
                      defaultMessage('Code to integrate BigQuery in Python')
                    "
                  >
                    "Code to integrate BigQuery in Python"
                  </span>
                </b-col>
              </b-row>
            </div>
            <div v-if="responseData.length == 0 && !addNewConversation"></div>
          </div>

          <footer>
            <b-row>
              <b-col class="d-flex justify-content-center w-100">
                <div class="div-text-area">
                  <b-form-textarea
                    id="textarea"
                    placeholder="Send message..."
                    v-model="message"
                    rows="1"
                    class="text-area"
                  >
                  </b-form-textarea>
                  <div class="send-icon">
                    <feather-icon
                      icon="SendIcon"
                      size="22"
                      class="feather"
                      @click="sendMesage(message)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BAvatar,
  BFormTextarea,
  BNav,
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "@axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import MarkdownIt from "markdown-it";
import mathjax3 from "markdown-it-mathjax3";
import { ref, nextTick, watchEffect } from "@vue/composition-api";
export default {
  components: {
    Loading,
    BForm,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BNav,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {};
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const message = ref("");
    const responseData = ref([]);
    const storedData = ref([]);
    const data = ref(0);
    const contentElm = ref(null);
    const listLoading = ref(false);
    const conversationLoading = ref(false);
    const listConversationData = ref([]);
    const selectedConversationId = ref("");
    const addNewConversation = ref(false);
    const isLoading = ref(false);
    const active = ref(false);
    const contentHtml = ref("");
    const token = localStorage.getItem("accessToken");

    const md = new MarkdownIt({
      linkify: true,
      highlight(code, lang) {
        const language = hljs.getLanguage(lang) ? lang : "plaintext";
        return `<pre class="hljs-code-container my-3"><div class="hljs-code-header d-flex align-center justify-space-between bg-grey-darken-3 pa-1"><span class="pl-2 text-caption text-white language">${language}</span><button class="hljs-copy-button ml-auto" data-copied="false">Copy</button></div><code class="hljs language-${language}">${
          hljs.highlight(code, { language: language, ignoreIllegals: true })
            .value
        }</code></pre>`;
      },
    });
    md.use(mathjax3);

    watchEffect(async () => {
      if (responseData.value.length !== 0) {
        for (const [key, value] of Object.entries(responseData.value)) {
          contentHtml.value = value.content ? md.render(value.content) : "";
          await nextTick();
          bindCopyCodeToButtons();
        }
      }
    });

    const bindCopyCodeToButtons = () => {
      if (!contentElm.value) {
        return;
      }
      document
        .querySelectorAll(".hljs-code-container")
        .forEach((codeContainer) => {
          const copyButton = codeContainer.querySelector(".hljs-copy-button");
          const codeBody = codeContainer.querySelector("code");
          copyButton.onclick = function () {
            navigator.clipboard.writeText(codeBody.textContent ?? "");
            copyButton.innerHTML = "✓ Copied!";
            copyButton.dataset.copied = "true";
            setTimeout(() => {
              copyButton.innerHTML = "Copy";
              copyButton.dataset.copied = "false";
            }, 2000);
          };
        });
    };
    bindCopyCodeToButtons();

    const getContentData = (item) => {
      return md.render(item);
    };
    const deleteConversation = (conversation_id) => {
      conversationLoading.value = true;
      axios
        .delete(`/chatgpt/conversations/${userData.id}/${conversation_id}`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          conversationLoading.value = false;
          ListConversation();
          addNewConversation.value = true;
        })
        .catch(() => {
          conversationLoading.value = false;
          addNewConversation.value = true;
        });
    };

    const ListConversation = () => {
      listLoading.value = true;
      axios
        .get(`/chatgpt/conversations/${userData.id}`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          listConversationData.value = [];
          listLoading.value = false;
          for (const [key, value] of Object.entries(response.data.topics)) {
            if (value !== null) {
              listConversationData.value.push(value);
            }
          }
        })
        .catch(() => {
          listLoading.value = false;
        });
    };

    ListConversation();
    addNewConversation.value = true;

    const defaultMessage = (item) => {
      message.value = item;
    };
    const showConversation = (conversation_id) => {
      conversationLoading.value = true;
      responseData.value = [];
      addNewConversation.value = false;
      axios
        .get(`/chatgpt/conversations/${userData.id}/${conversation_id}`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data) {
            conversationLoading.value = false;
            selectedConversationId.value = response.data.topic.conversation_id;
            delete response.data.topic;
            for (const [key, value] of Object.entries(response.data)) {
              responseData.value.push(value);
            }
          }
        })
        .catch(() => {
          conversationLoading.value = false;
        });
    };
    const newConversation = () => {
      responseData.value = [];
      selectedConversationId.value = "";
      addNewConversation.value = true;
    };
    const sendMesage = (newMessage) => {
      const messageObj = JSON.stringify({ text: newMessage });
      message.value = "";
      isLoading.value = true;
      addNewConversation.value = false;

      if (responseData.value.length == 0) {
        responseData.value.push({ content: newMessage });
        axios
          .post(`/chatgpt/conversations/${userData.id}`, messageObj, {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            isLoading.value = false;
            selectedConversationId.value = response.data.conversation_id;
            responseData.value.push(response.data);
            ListConversation();
          })
          .catch((err) => {
            isLoading.value = false;
            responseData.value.push({ content: err.response.data.detail });
          });
      } else {
        responseData.value.push({ content: newMessage });
        axios
          .post(
            `/chatgpt/conversations/${userData.id}/${selectedConversationId.value}`,
            messageObj,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            isLoading.value = false;
            responseData.value.push(response.data);
          })
          .catch((err) => {
            isLoading.value = false;
            responseData.value.push({ content: err.response.data.detail });
          });
      }
    };

    return {
      userData,
      message,
      responseData,
      storedData,
      data,
      contentElm,
      listLoading,
      conversationLoading,
      listConversationData,
      selectedConversationId,
      isLoading,
      active,
      md,
      addNewConversation,
      getContentData,
      deleteConversation,
      ListConversation,
      defaultMessage,
      showConversation,
      newConversation,
      sendMesage,
      bindCopyCodeToButtons,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.hljs-code-container {
  background-color: $black !important;
  border-radius: 5px;
  overflow: hidden;
}
.hljs-code-header {
  background-color: $hljs-bg !important;
}
.language {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
.hljs-copy-button {
  width: 2rem;
  height: 2rem;
  text-indent: -9999px;
  color: $white;
  background-color: $hljs-bg;
  border-radius: 0.25rem;
  border: None;
  background-image: url('data:image/svg+xml;utf-8,<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H16C15.4477 5 15 4.55228 15 4C15 3.44772 15.4477 3 16 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H6Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C7 1.89543 7.89543 1 9 1H15C16.1046 1 17 1.89543 17 3V5C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5V3ZM15 3H9V5H15V3Z" fill="white"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 200ms ease, transform 200ms ease-out;
}
.hljs-copy-button:hover {
  background-color: $hljs-bg-hover;
}
.hljs-copy-button:active {
  background-color: $hljs-bg-active;
}
.hljs-copy-button[data-copied="true"] {
  text-indent: 0;
  width: auto;
  background-image: none;
}
@media (prefers-reduced-motion) {
  .hljs-copy-button {
    transition: none;
  }
}
.MathJax svg {
  max-width: 100%;
  overflow: auto;
}
.language-title {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  color: $white;
}
.card-height-chatgpt {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.chatbox {
  display: flex;
  flex-direction: column;
  height: 85%;
  overflow-y: auto;
  margin-bottom: 5px;
}
.style {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.style-list {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-top: 4px;
  color: $black;
  border-radius: 0.375rem;
}
.style-list:hover {
  background-color: $pagination-hover-bg;
}
.feather {
  margin-right: 0.5rem;
}
.text-outer {
  display: flex;
  flex-direction: column;
}
.text-inner {
  padding: 10px;
  margin-bottom: 15px;
}
.text-from {
  color: $black;
}
.selected-conversation {
  background: $conversation-bg;
  border-radius: 0.375rem;
}
.no-selected-conversation {
  background-color: none;
}
.text-to {
  background: $text-to-bg;
  border-top: 1px solid $text-to-border-color;
  border-bottom: 1px solid $text-to-border-color;

  color: $black;
}
.div-color {
  background: $div-bg;
}
.name {
  overflow: hidden;
  word-break: break-all;
  max-height: 1.25rem;
  max-width: 11rem;
}
#app
  > div.horizontal-layout.horizontal-menu.navbar-floating.footer-static
  > div.app-content.content
  > div.content-wrapper
  > div
  > div
  > div
  > div.col-lg-9.col-12
  > div
  > div {
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
}
.action-button {
  display: flex;
  position: absolute;
  right: 2rem;
}
.span {
  color: $chat-color;
  margin-top: 5px;
  font-size: 1rem;
  line-height: 1.75;
}
.avatar-chat {
  margin-top: 5px;
}
.text-area {
  border: none;
  box-shadow: none;
  overflow-y: hidden;
  width: 100%;
}
.div-text-area {
  display: flex;
  flex-direction: row;
  border-radius: 0.375rem;
  max-height: 50px;
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.example-listing {
  background-color: $div-bg;
  padding: 10px;
  cursor: pointer;
}
.send-icon {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
code {
  color: $white !important;
  background-color: $black;
  font-size: 1rem;
  line-height: 1.75;
  padding: 0;
}
code:not(.hljs) {
  color: $black !important;
  background-color: transparent !important;
  font-weight: bold;
}

#app
  > div.horizontal-layout.horizontal-menu.navbar-floating.footer-static
  > div.app-content.content
  > div.content-wrapper
  > div
  > div
  > div
  > div.col-sm-12.col-md-8.col-lg-9.col-12
  > div
  > div
  > div
  > div.text-outer
  > div
  > div
  > div
  > div
  > div.span.col
  > div
  > div {
  background-color: $black;
  padding: 20px;
  border-radius: 0.375rem;
}
</style>
